import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onBeforeMount, ref} from 'vue';
import {
    getTutors,
    // isReport,
} from '@/assets/js/modules/learn/learn-common';
import {
    // stringCheck,
    // getItem, getItems,
    // getResult,
    isSuccess,
    // lengthCheck,
    convertToStorageBytes,
    timestampToDateFormat,
} from '@/assets/js/util';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_TASK_DIST_TY_LIST
    ,ACT_UPLOAD_TASK_ATCH_FILE
    ,ACT_GET_TASK_SNDS_LIST
    ,ACT_INSERT_TASK_REPORT_RSLTS
} from "@/store/modules/task/task";
import ApiService from "@/assets/js/api.service";

export const learnReportSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const {
        showMessage,
        showConfirm,
        showLoading,
        hideLoading
    } = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const parts = ref([]);
    const isReady = ref(false);
    const attachments = ref({});
    const isLoading = ref(false);

    const uploadTaskAtch = (payload) => {
        console.log("payload",payload)
        return store.dispatch(`task/${ACT_UPLOAD_TASK_ATCH_FILE}`, payload);
    };

    const atchFile = ref([]);


    const attachedFile = (event,idx) => {
        console.log("index:" ,idx);
        taskSndsList.value[idx].fileNm =  event.target.files[0].name;
        taskSndsList.value[idx].fileSz =  event.target.files[0].size;
        attachments.value[taskSndsList.value[idx].lrnTaskTySn] = event.target.files[0];
        console.log(taskSndsList.value[idx]);
    }

    const uploadAtchFile = async (taskTySn) => {
        console.log("uploadAtachFile")
                // console.log(taskTySn);
                if (attachments.value[taskTySn].name != null) { //파일확인
                    let atchRes = await uploadTaskAtch({
                        params: { lrnTaskTySn:taskTySn, lrnTaskMstSn: props.rpt.lrnTaskMstSn },
                        files: [attachments.value[taskTySn]]
                    });

                    // console.log(taskSndsList.value)

                    if (atchRes && atchRes.data && atchRes.data.atchFileDomain) {
                        let idx = taskSndsList.value.findIndex(x => x.lrnTaskTySn == taskTySn)
                        console.log(idx);
                        console.log(  taskSndsList.value[idx])
                        taskSndsList.value[idx].itnPath = atchRes.data.atchFileDomain.itnPath;
                        taskSndsList.value[idx].etnPath = atchRes.data.atchFileDomain.etnPath;
                        taskSndsList.value[idx].buketNm = atchRes.data.atchFileDomain.buketNm;
                        console.log("CheckFile: ",taskSndsList.value[idx])
                    }else{
                        // 업로드결과 오류
                        showMessage("첨부파일이 업로드에 실패했습니다. 첨부파일을 확인 후 해당 문제가 계속하여 발생할 경우 담당자에게 문의해주시기 바랍니다.")
                    }

                }

    };
    const submitReport = async () => {

        // 첨부파일 체크
        if (Object.keys(attachments.value).length >0) {
            // 첨부파일 업로드처리
            console.log("attachments lenth check")
            for (let key in attachments.value){
                await uploadAtchFile(key);
            }
            // 완료후 insert
            setReport();

        } else {
            setReport();
        }
    }
    const setReport = () => {
        isLoading.value = true;
        console.log("insert/update Check: ", taskSndsList.value);

        store.dispatch(`task/${ACT_INSERT_TASK_REPORT_RSLTS}`, taskSndsList.value)
            .then(res => {
                if(isSuccess(res)) {
                    showMessage('레포트가 등록되었습니다.');
                    isLoading.value = false;
                    closeModal();
                    emit('renewEnd');

                }
            }).catch(e => {
                console.error(e);
                showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                isLoading.value = false;
            });

    }



    const taskTyList = ref([]);
    const taskSndsList = ref([]);


    const submitTaskList = ref([]);
    const removeFile = (idx,taskTySn) => {
        showConfirm({
            title: '첨부파일 삭제',
            text: '선택된 파일을 삭제하시겠습니까?',
            callback: () => {
                //리스트에서 내용 제거
                console.log(taskTySn)
                console.log(attachments.value[taskTySn]);
                delete attachments.value[taskTySn];
                taskSndsList.value[idx].fileNm = null;
                taskSndsList.value[idx].buketNm = null;
                taskSndsList.value[idx].fileSz = null;
                taskSndsList.value[idx].itnPath = null;
                taskSndsList.value[idx].etnPath = null;
            }
        });
    }

    const initTask = ()=>{

        taskTyList.value =[];
        submitTaskList.value = [];

    }


    const toggleEtc = (question) => {
        question.etc = !question.etc;
        if (question.etc) {
            if(question.qstnKindCdDcd === '2020002') {
                question.model = [];
            } else {
                question.model = ''
            }
        } else {
            question.etcAns = '';
        }
    }



    const clickBack = () => {
        if (props.item.cntstCrseYn === 'Y' || props.item.splCrseTyCdDcd ==='2066030') {
            if (props.item.svyRsltCnt > 0) {
                router.push({
                    name: 'ApplyTrainView',
                    params: {distCrseSn: props.item.distCrseSn}
                });
            }
        } else {
            const currentQuery = {...route.query};
            delete currentQuery.modal;
            router.push({query: currentQuery});
        }
    }

    const closeModal = () => {
        if (isMobile.value) {
            clickBack();
        } else {
            emit('update:active', false);
        }
    }

    const setSubmitInfo = (lst) =>{
        lst.value.forEach(item =>{
            item.lrnerId = session.value.lrnerId;
            item.lrnerNm = session.value.lrnerNm;
            item.deptCd = session.value.deptCd;
            item.deptNm = session.value.deptNm;
        })
    }

    const getTaskTyList = () => {
        initTask();
        isLoading.value = true;
        getListFunc(`task/${ACT_GET_TASK_DIST_TY_LIST}`,  {distCrseSn: props.rpt.distCrseSn, lrnTaskMstSn: props.rpt.lrnTaskMstSn}, taskTyList,null,()=>{
            // 등록 리스트 조회 - STT값 확인을 통한 등록여부 체크
            getListFunc(`task/${ACT_GET_TASK_SNDS_LIST}`,  {distCrseSn: props.rpt.distCrseSn, lrnTaskMstSn: props.rpt.lrnTaskMstSn}, taskSndsList,null,()=>{
                isLoading.value = false;
                //등록자 정보 셋팅
                setSubmitInfo(taskSndsList);
            });
        });
    }
    const exDownloadFile = (file) => {
        console.log(file);
        showLoading();
        ApiService.download(`/v1/app/task/exAtchs/${file.lrnTaskTyFileSn}`,
            null, null, file.fileNm, () => {hideLoading()}
        );
    }

    const atchFileDown = (file) => {
        console.log(file);
        showLoading();
        ApiService.download(`/v1/app/task/atchs/${file.lrnTaskSbmsnSn}`,
            null, null, file.fileNm, () => {hideLoading()}
        );
    }

    onBeforeMount(()=>{
        getTaskTyList();
    })

    return {
        isReady,
        parts,
        closeModal,
        toggleEtc,
        // submitSurvey,
        getTutors,
        timestampToDateFormat,
        taskTyList,
        // uploadAtchFile,
        uploadTaskAtch,
        convertToStorageBytes,
        atchFile,
        submitTaskList,
        attachedFile,
        removeFile,
        taskSndsList,
        isLoading,
        submitReport,
        exDownloadFile,
        atchFileDown,
    }
}
