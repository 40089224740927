<template>
  <div class="popup-container is-active">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">{{rpt.lrnTaskNm}}</h3>
        </header>
        <div class="popup-content" >
          <article class="popup-section">
            <div class="section-content">
              <div class="kb-form-fields" v-if="!isLoading">
              <!--유형별 레포트 제출-->
              <div v-for="(item, idx) in taskTyList" :key="idx" style="margin-top:40px;" >
                <header class="section-header"><h4 class="title">{{item.lrnTaskTyNm}}</h4></header>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">레포트설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li >
                        <span class="file-name-placeholder kb-form-text">{{item.lrnTaskTyCn}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">제출양식</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list"  v-if="item.lrnTaskTyFiles.length >0">
                      <li class="file-item" v-for="(file, fileIdx) in item.lrnTaskTyFiles" :key="fileIdx">
                        <span class="file-name-placeholder file-name" @click="exDownloadFile(file)" style="cursor:pointer;">{{file.fileNm}} <i class="icon-download"></i></span>
                      </li>

                    </ul>
                    <ul class="file-list" v-else>
                      <li >
                        <span class="file-name-placeholder kb-form-text">-</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">레포트제목</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <input type="text" v-model.trim="taskSndsList[idx].taskTitle" class="kb-form-control" placeholder="제목을 입력하세요."/>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole py-3">
                    <div class="editor-content  ms-0">
                      <textarea   v-model.trim="taskSndsList[idx].taskCn" class="kb-form-control" rows="2" placeholder=" 레포트의 설명을 작성해주시기 바랍니다."></textarea>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">제출파일</strong>
                    </label>
                  </div>
                  <div class="kb-form-column" v-if="!isLoading">
                    <ul class="file-list">
                      <li class="file-item" v-if="taskSndsList[idx].fileNm !== null">
                        <span class="file-name" >{{taskSndsList[idx].fileNm }}</span>
                        <span class="file-name" v-if="taskSndsList[idx].buketNm !== null" style="cursor:pointer;" @click="atchFileDown(taskSndsList[idx])"><i class="icon-download"></i></span>
                        <span class="file-size">{{convertToStorageBytes(taskSndsList[idx].fileSz) }}</span>
                        <button class="kb-btn-file-delete" @click="removeFile(idx,item.lrnTaskTySn)"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else>
                        <span class="file-name-placeholder kb-form-text">파일을 업로드해주세요.</span>
                      </li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end">
                    <div class="kb-form-file">
                      <input  class="kb-form-file-input" type="file" :id="'fileInput'+idx" @change="attachedFile($event,idx)" >
                      <label :for="'fileInput'+idx"  class="kb-btn-link"><span class="text">{{taskSndsList[idx].fileNm !== null? `파일수정` : `파일첨부` }}</span></label>
                    </div>
                  </div>
                </div>
              </div>

              </div>
            </div>

          </article>
          <LoadingDiv v-if="isLoading" />
        </div>
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitReport"><span class="text">제출</span></button>
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
<!--  <Preview v-if="pcPreviewModal && isCollective.etnUrl" v-model="pcPreviewModal" :item="isCollective" />-->
</template>

<script>
import LoadingDiv from '@/components/common/LoadingDiv.vue';
// import FileUpload from '@/components/history/module/FileUpload';
import {learnReportSetup} from "@/assets/js/modules/learn/learn-report-setup";
export default {
  name: 'LearnReport',
  components: {LoadingDiv},
  props: {
    active: Boolean,
        item: Object,
        rpt: Object,
        hasCallback: Boolean
  },
  emits: ['update:active', 'renewEnd'],
      setup: learnReportSetup
};
</script>